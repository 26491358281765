import React, { useEffect, useRef, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Layout from 'components/layout'
import AeditLink from 'services/link'
import SEO from 'components/seo'
import { Container, Row, Col } from 'components/grid'
import { Track } from 'services/analytics'
import { getErrorQuote } from 'services/util/legacy'
import { redirectTo } from 'services/next'
import { inDevelopment } from 'services/env'
import { noop } from 'services/util/abstract'
import { GlobalContext } from 'components/global'
import styles from './error.scss'
import { log } from 'services/util/log'

const FallbackPage = props => {
  const { onLeavingErrorPage = noop, statusCode, quote, displayAuthScreen, skipLog } = props
  const { currentUser, toggleAuth } = useContext(GlobalContext)
  const [, setCurrentUser] = useState(currentUser)

  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', onLeavingErrorPage)
    return () => {
      router.events.off('routeChangeStart', onLeavingErrorPage)
    }
  }, [onLeavingErrorPage])

  // use a ref so the quote doesnt change on re-render
  const quoteRef = useRef(!!quote ? quote : getErrorQuote(statusCode))

  useEffect(() => {
    if (!skipLog) {
      log(`Fallback page loaded with status code ${statusCode}`, 'error')
    }
    if (!inDevelopment) {
      Track.errorPageLoaded(statusCode)
    }
  }, [statusCode])

  useEffect(() => {
    if (displayAuthScreen) {
      toggleAuth('login', { reloadPostAuth: true })
    }
  }, [displayAuthScreen])

  useEffect(() => {
    setCurrentUser(prevUser => {
      if (prevUser && !currentUser) {
        // user has just signed out
        redirectTo('/')
      }
      return currentUser
    })
  }, [currentUser])

  return (
    <>
      <Layout hiddenElements={['container']}>
        <SEO title={`Error ${statusCode}`} description={quoteRef.current} />
        <div className="error-container">
          <Container>
            <Row>
              <Col md={{ size: 11, offset: 1 }}>
                <div className="content-container">
                  {statusCode ? (
                    <div className="title-container">
                      <div className="status">{`${statusCode} ERROR`}</div>
                      <div className="quote">{quoteRef.current}</div>
                    </div>
                  ) : (
                    'An error occurred on client'
                  )}
                  <div className="description">
                    <p>
                      Looks like something went wrong on our end. Here are a few things that may be
                      helpful:
                    </p>
                    <AeditLink className="description-link" pathname="/">
                      Home
                    </AeditLink>
                    <AeditLink className="description-link" pathname="/aedition">
                      The AEDITION
                    </AeditLink>
                    <AeditLink className="description-link" pathname="/procedures">
                      All Procedures
                    </AeditLink>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
      <style jsx>{styles}</style>
    </>
  )
}

FallbackPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
  onLeavingErrorPage: PropTypes.func,
  quote: PropTypes.string,
}

export default FallbackPage
